import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Icon,
  Input,
  List,
  Modal,
  Segment,
} from "semantic-ui-react";

import CardOrderSetTemplateUX from "./CardOrderSetTemplateUX";
import FormOrderDetailList from "react-lib/apps/HISV3/common/FormOrderDetailList";
//STYLE
import "react-lib/apps/HISV3/common/CardOrderSetTemplate.scss";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
import SearchBox from "react-lib/appcon/common/SearchBox";

const CARD_ORDER_SET_TEMPLATE = "CardOrderSetTemplateUX";

const typeOrderOption = [
  { key: "ALL", value: "ALL", text: "ทั้งหมด" },
  { key: "ชุดคำสั่งกลาง", value: "ชุดคำสั่งกลาง", text: "ชุดคำสั่งกลาง" },
  { key: "ชุดคำสั่งประจำแผนก", value: "ชุดคำสั่งประจำแผนก", text: "ชุดคำสั่งประจำแผนก" },
  { key: "ชุดคำสั่งแพทย์", value: "ชุดคำสั่งแพทย์", text: "ชุดคำสั่งแพทย์" },
];

const typeOrderDetailOption = [
  { key: "ALL", value: "ALL", text: "ALL" },
  { key: "DRUG", value: "DRUG", text: "Medication" },
  { key: "PROC", value: "PROC", text: "Treatment" },
  { key: "SUPPLY", value: "SUPPLY", text: "Supply" },
  { key: "LAB", value: "LAB", text: "Lab" },
  { key: "XRAY", value: "XRAY", text: "X-ray" },
];

const mobOrderTemplateList = [
  {
    id: 1,
    name: "ชุด Treatment ทดสอบ 1",
    type: "ชุดคำสั่งกลาง",
  },
  {
    id: 2,
    name: "ชุด Treatment ทดสอบ 2",
    type: "ชุดคำสั่งกลาง",
  },
  {
    id: 3,
    name: "ชุด Treatment ทดสอบ 3",
    type: "ชุดคำสั่งประจำแผนก",
  },
  {
    id: 4,
    name: "ชุด Treatment ทดสอบ 4",
    type: "ชุดคำสั่งแพทย์",
  },
];

type CardOrderSetTemplateProps = {
  runSequence: (e: any) => any;
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  OrderSetTemplateSequence?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // CommonInterface
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  searchedItemList?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  supplyOrderEligibilityOptions?: any[];
};

const CardOrderSetTemplate = (props: CardOrderSetTemplateProps) => {
  const [typeOrderDetail, setTypeOrderDetail] = useState<string>("ALL");
  const [searchProduct, setSearchProduct] = useState(null);
  const [showTemplateDetail, setShowTemplateDetail] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "OrderSetTemplate",
      restart: true,
    });

    props.onEvent({ message: "getChoicesSupplyOrderEligibility", params: {} });
  }, []);

  const productList = useMemo(() => {
    return (props.searchedItemList || [])
      ?.filter((item: any) =>
        typeOrderDetail !== "ALL" ? typeOrderDetail === item?.p_type_code : item
      )
      ?.map((item: any) => {
        return {
          ...item,
          codeProduct: <div style={{ marginLeft: "20px" }}>{item?.code}</div>,
          nameProduct: <div style={{ marginLeft: "20px" }}>{item?.name}</div>,
          typeProduct: (
            <div style={{ marginLeft: "20px" }}>
              {
                typeOrderDetailOption.find((option: any) => option.value === item?.p_type_code)
                  ?.text
              }
            </div>
          ),
        };
      });
  }, [props.searchedItemList]);

  const orderTemplateList = useMemo(() => {
    return ["Medication", "Lab", "X-ray", "Treatment", "Supply"].map((key: string) => {
      let filterData = (props.OrderSetTemplateSequence?.selectTemplate?.items || [])
        .filter(
          (item: any) =>
            typeOrderDetailOption.find((option: any) => option.value === item?.p_type_code)
              ?.text === key
        )
        .map((item: any) => {
          return {
            ...item,
            drugUnits:
              props.masterOptions?.unit
                ?.filter((item: any) => item.value === item?.drugUnits)?.[0]
                ?.text?.split("(")?.[0] || "",
          };
        });

      return {
        name: key,
        list: filterData,
      };
    });
  }, [props.OrderSetTemplateSequence?.selectTemplate?.items]);

  const handleChangeData = (e: any, v: any) => {
    let value = v.type === "checkbox" ? v.checked : v.value;

    if (v.name === "type") {
      props.setProp(`OrderSetTemplateSequence.selectTemplate`, {
        ...props.OrderSetTemplateSequence?.selectTemplate,
        [`${v.name}`]: value,
        ...(value !== "ชุดคำสั่งประจำแผนก" && {
          divisionDetail: "",
        }),
        ...(value !== "ชุดคำสั่งของแพทย์" && {
          doctorDetail: "",
        }),
      });
    } else if (v.name === "typeOrderDetail") {
      setTypeOrderDetail(value);
    } else {
      props.setProp(`OrderSetTemplateSequence.selectTemplate.${v.name}`, value);
    }
  };

  const handleAnesthesiologist = (value: any) => {
    handleChangeData(null, { name: "doctorDetail", value });
  };

  const handleNewOrderTemplate = () => {
    setSearchProduct(null);
    setTypeOrderDetail("ALL");
    setShowTemplateDetail(true);
    props.setProp("OrderSetTemplateSequence.selectTemplate", {
      type: "ชุดคำสั่งกลาง",
    });
  };

  const handleSelectedProductList = (item: any) => {
    if (item !== null) {
      let newItem = {
        ...item,
        detail: " ",
        ...(item?.p_type_code === "DRUG"
          ? {
              dose: 1,
            }
          : {
              qty: 1,
            }),
      };

      props.setProp("OrderSetTemplateSequence.selectTemplate.items", [
        ...(props.OrderSetTemplateSequence?.selectTemplate?.items || []),
        newItem,
      ]);

      setSearchProduct(null);
    } else {
      setSearchProduct(item);
    }
  };

  const handleSelectedTemplate = (item: any) => {
    setSearchProduct(null);
    setTypeOrderDetail("ALL");
    setShowTemplateDetail(true);

    props.setProp("OrderSetTemplateSequence.selectTemplate", item);
  };

  return (
    <CardOrderSetTemplateUX
      typeOrderOption={typeOrderOption}
      typeOrderDetailOption={typeOrderDetailOption}
      typeOrderDetail={typeOrderDetail}
      showTemplateDetail={showTemplateDetail}
      orderDivisionOption={props.masterOptions?.division}
      orderDetail={props.OrderSetTemplateSequence?.selectTemplate}
      onChangeData={handleChangeData}
      templateList={
        <List selection celled verticalAlign="middle">
          {mobOrderTemplateList?.map((item: any, index: number) => {
            return (
              <List.Item
                key={index}
                style={{
                  ...(props.OrderSetTemplateSequence?.selectTemplate?.id === item.id && {
                    background: "#D6ECF3",
                  }),
                  ...(index === 0 && {
                    borderTop: "none",
                  }),
                }}
                onClick={() => handleSelectedTemplate(item)}
              >
                <List.Content floated="left">
                  <List.Header>{item.name}</List.Header>
                </List.Content>
                <List.Content floated="right">
                  <div style={{ color: "gray" }}>{`[${item.type}]`}</div>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      }
      orderDetailList={
        <FormOrderDetailList
          runSequence={props.runSequence}
          setProp={props.setProp}
          masterOptions={props.masterOptions}
          supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
          orderTemplateList={orderTemplateList}
          buttonLoadCheck={props.buttonLoadCheck}
          OrderSetTemplateSequence={props.OrderSetTemplateSequence}
        />
      }
      addNewOrder={
        <Button color="green" size="mini" icon onClick={handleNewOrderTemplate}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon name="plus square outline" size="large" />
            สร้างชุดคำสั่งใหม่
          </div>
        </Button>
      }
      searchDoctorDetail={
        <SearchBoxWithKey
          // callback
          onEvent={props.onEvent}
          placeholder="ชื่อแพทย์"
          type="Doctor"
          id={"doctorDetail"}
          searchedItemListWithKey={props.searchedItemListWithKey}
          selectedItem={props.OrderSetTemplateSequence?.selectTemplate?.doctorDetail || null}
          setSelected={handleAnesthesiologist}
        />
      }
      searchOrderList={
        <SearchBox
          onEvent={props.onEvent}
          // config
          type="ProductAll"
          style={{ width: "100%" }}
          fluid={true}
          useWithKey={false}
          icon="search"
          // Select
          searchedItemList={productList}
          selectedItem={searchProduct || null}
          setSelectedItem={handleSelectedProductList}
          vertical={true}
          showTable={true}
          widths="120,auto,auto"
          headers="Code,ชื่อ,ประเภท"
          keys="codeProduct,nameProduct,typeProduct"
          toDisplay={(item: any) => item.name}
        />
      }
      closeTemplate={() => setShowTemplateDetail(false)}
      saveTemplate={() => setShowTemplateDetail(false)}
    />
  );
};

export default React.memo(CardOrderSetTemplate);
